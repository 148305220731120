import {useContext, useState, useEffect} from "react";
import _ from "lodash";
import { ThemeContext } from "provider/ThemeProvider";

const clientOverride = {
  "OptumEWS": {},
  "Premium": {
    providerSearch: {
      link: "https://www.liveandworkwell.com/en/member/MetLife/getcare"
    }
  }
}

const resourceData = {
  ableTo: {
    category: ["Coaching"],
    title: "Schedule a no-cost virtual coaching session",
    description: "Share your goals and challenges, and we’ll provide your covered options.",
    link: "https://www.ableto.com/",
  },
  calm: {
    category: ["Self-Care"],
    title: "The Calm app - help to stress less and feel better",
    description:
      "Get meditations, breathing exercises, sleep stories, daily tips and more. All designed to help you stress less, sleep more and live mindfully",
    link: "https://www.calm.com/b2b/ews/subscribe?code=optum",
  },
  bookCoaching: {
    category: ["Coaching", "Therapy"],
    title: "Book Virtual Coaching and Therapy Visits anytime, anywhere",
    description: "Improve mental health in the comfort of your own home. Virtual Visits are confidential & no-cost for you.",
    link: "https://app.mindfi.co/optum?utm_source=livewell-schedule-card&access_code=globaldemo&lang=en-UK&country=MY",
  },
  legal: {
    category: ["Financial and Legal"],
    title: "Legal counseling and access to legal form kits",
    description: "Legal support US CLC",
    link:
      "https://www.clcmembers.net/launch/6d700460-6820-11e8-9ef8-1428ea656a80?company_code=Textron",
  },
  financial: {
    category: ["Financial and Legal"],
    title: "Financial Wellbeing powered by BrightPlan",
    description:
      "Articles, calculators and content to help support Financial Wellbeing, or schedule a call with a Financial Coach",
    link:
      "https://academy.brightplan.com/?countrycode=us&gl=1&company_code=globaldemo",
  },
  financialInt: {
    category: ["Financial and Legal"],
    title: "Financial Wellbeing powered by BrightPlan",
    description:
      "Articles, calculators and content to help support Financial Wellbeing",
    link:
      "https://academy.brightplan.com/?countrycode=us&gl=1&company_code=globaldemo",
  },
  talkspace: {
    category: ["Therapy"],
    title: "Talkspace",
    description: "Virtual 1:1 Therapy sessions",
    link: "https://www.talkspace.com/connect",
  },
  findHelp: {
    category: ["Self-Care"],
    title: "Findhelp.org",
    description: "Aggregating website to guide members to emergency services",
    link: "https://www.findhelp.org/",
  },
  childSupport: {
    category: ["Coaching"],
    title: "Child Caregiver Support",
    description: "Neurodiverse caretaker/parenting support",
    link: "https://connect.rethinkbenefits.com/sponsor/optumewstrial",
  },
  professionalResilience: {
    category: ["Coaching"],
    title: "Professional Resilience",
    description: "Support for Neurodiverse employees and managers",
    link: "https://connect.rethinkbenefits.com/sponsor/optumewstrial",
  },
  personalWellbeing: {
    category: ["Self-Care", "Digital"],
    title: "Personal Wellbeing",
    description: "On demand content for managing Anxiety, stress, and more",
    link: "https://connect.rethinkbenefits.com/sponsor/optumewstrial",
  },
  providerSearch: {
    category: ["Therapy"],
    title: "Provider Search",
    description: "Access to Optum provider directory",
    link:
      "https://www.liveandworkwell.com/?pin=ohdemoeap&redirectURL=/services/providerSearch",
  },
  premiumNetwork: {
    category: ["Therapy"],
    title: "Premium Network",
    description: "Access a provider in 2-5 days",
    link: "",
  },
  peerToPeer: {
    category: ["Self-Care", "Coaching"],
    title: "Peer to peer Support",
    description:
      "24/7 anonymous live chat support with your peers to discuss loneliness, stress, relationship issues and more",
    link: "",
  },
  mindfi: {
    category: ["Therapy", "Coaching"],
    title: "MindFi Virtual Coaching and Therapy",
    description: "Schedule Virtual Coaching or Therapy sessions ",
    link:
      "https://app.mindfi.co/optum?utm_source=livewell-resources-card&access_code=globaldemo&lang=en-UK&country=PH",
  },
};



export const useResources = () => {
  const { data } = useContext(ThemeContext);
  const {accesscode} = data

  const [finalData, setFinalData] = useState(null); 


  useEffect(() => {
    setFinalData(getData(accesscode))
  }, [accesscode])
  
  const getData = (accesscode) => {
    let returnData = _.cloneDeep(resourceData)
    // apply overrides if clientOverride has the current access code
    if (clientOverride[accesscode]) {
      returnData = _.merge(returnData, clientOverride[accesscode]);
    } 

    return returnData
  }

  return finalData
}

export default resourceData